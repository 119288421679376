(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/article-components/assets/article.js') >= 0) return;  svs.modules.push('/components/tipsen/article-components/assets/article.js');
"use strict";

const {
  useEffect
} = React;
const {
  useSelector,
  useDispatch
} = ReactRedux;
const {
  selectArticleIsRejected,
  selectArticleShouldShowSkeleton,
  selectArticle
} = svs.components.tipsen.article.selectors;
const {
  EmptyNotice
} = svs.component.tipsenEmptyNotice;
const {
  fetchArticle
} = svs.components.tipsen.article.actions;
const useArticle = articleId => {
  const article = useSelector(state => selectArticle(state, articleId));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchArticle({
      articleId
    }));
  }, [articleId, dispatch]);
  return article;
};
const Article = _ref => {
  let {
    className,
    articleId,
    noArticleMessage = 'Artikeln finns inte'
  } = _ref;
  const isLoading = useSelector(state => selectArticleShouldShowSkeleton(state, articleId));
  const isError = useSelector(state => selectArticleIsRejected(state, articleId));
  const article = useArticle(articleId);
  const hasArticle = article === null || article === void 0 ? void 0 : article.body;
  const isEmpty = !(article !== null && article !== void 0 && article.body);
  const hasIngress = article === null || article === void 0 ? void 0 : article.ingress;
  const classNames = [];
  className && classNames.push(className);
  return React.createElement("div", {
    className: ['tipsen-article'].concat(classNames).join(' ')
  }, isLoading && 'Laddar...', isError && !hasArticle && 'Det gick inte att ladda artikeln', hasIngress &&
  React.createElement("div", {
    className: "f-content",
    dangerouslySetInnerHTML: {
      __html: article.ingress
    }
  }), hasArticle &&
  React.createElement("div", {
    className: "f-content",
    dangerouslySetInnerHTML: {
      __html: article.body
    }
  }), !isLoading && isEmpty && React.createElement(EmptyNotice, {
    branding: className
  }, noArticleMessage));
};
setGlobal('svs.components.tipsen.articleComponents.Article', Article);

 })(window);